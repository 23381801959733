import React, { useRef, useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import ImagePaginationComponent from './ImagePagination';
import '../../App.css';

// function for chunking array to make row distribution easier
const arrayChunk = (arr, n) => {
	const array = arr.slice();
	const chunks = [];
	while (array.length) chunks.push(array.splice(0, n));
	return chunks;
};

const CropImageListComponent = () => {

	let navigate = useNavigate();
	function changeLocation(placeToGo){
		navigate(placeToGo, { replace: true });
		window.location.reload();
	}

	const { crop_id, label_type, photo_validity } = useParams();
	const [data, setData] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [numberOfPages, setNumberOfPages] = useState(1);
	const [paginationArray, setPaginationArray] = useState([]);
	
	useEffect(() => {
		const fetchData = async () => {
			try {
				const cookie = Cookies.get('cdexuser');
				const image_query = `https://www.cropdex.org/crops/crop-images-filtered?crop_id=${crop_id}&label_type=${label_type}&photo_validity=${photo_validity}`;
				const response = await fetch(image_query, { method: 'GET', headers: { 'Authorization': `Bearer ${cookie}`, 'Content-Type': 'application/json'}});
				const jsonData = await response.json();
				setData(jsonData);
				let pageCount = Math.max(1, Math.ceil(jsonData.length/15));
				setNumberOfPages(pageCount);

			} catch (error) { 
				console.error('Error fetching data:', error);
			}
		};
		fetchData();
	}, []);

	return (
		<div className="image-list-div">

			{ data != null && data != undefined ?
				(
					<>
						<Container fluid>
						{
							arrayChunk(data.slice((currentPage-1)*15, currentPage*15), 5).map((row, i) => (
								<Row key={i}>
									{row.map((col, j) => (
										<Col key={j+i} xs={5} md={3} lg={2} className="mb-3" lg={true}>
											<div className="image-gallery-container">
												<div className="square">
													<Image 
														className="img-fluid square-img"
														src={"https://www.cropdex.org/cropdex_image_data/retrieve-image?id=" + col.id}
														onClick={() => { changeLocation(`/crop-images/${crop_id}/${label_type}/${photo_validity}/${col.id}`) }}/>
												</div>
												<div className="bottom-left-text">By: {col.cropdex_user.display_name}<br/>Date: {col.date_taken}</div>
											</div>
										</Col>
									))}
									{row.length < 5 ?
										Array.from({ length: 5-row.length }).map((_, index) => (
											<Col key={i+row.length+index}></Col>
										))
									:null
									}
								</Row>
							))
						}
						</Container>
						<ImagePaginationComponent 
							currentPage={currentPage} 
							numberOfPages={numberOfPages} 
							setCurrentPage={setCurrentPage}/>
					</>
				)
				: <Spinner animation="border" />
			}
		</div>
	);
}

export default CropImageListComponent;